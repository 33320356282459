let errorQueue = [];
const send = function(e) {
    let log;
    for(let i=0;i<arguments.length;i++) log += arguments[i];

    errorQueue.push(log);
}

const sendErrors = function() {
    if (errorQueue.length === 0 || !window.sendShortAnalytic) return;

    for (let i = 0; i < errorQueue.length; i++) {
        sendShortAnalytic("console.error", {
            error: {
                message: errorQueue[i],
            }
        })
        console.log(`[WS] Sent error: ${errorQueue[i]}`);
    }

    errorQueue = [];
}

setInterval(sendErrors, 1000)

const nativeConsole={
    log:console.log.bind(console),
    warn:console.warn.bind(console),
    clear:console.clear.bind(console),
    error:console.error.bind(console),
}

window.onunhandledrejection = send
window.onerror=send;

console.error = function() {
    send(...arguments);
    nativeConsole.error.apply(console,arguments);
};